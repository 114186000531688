import { ChakraProvider } from '@chakra-ui/react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { AppRoutes } from './AppRoutes';
import { Footer } from './shared/components/footer';
import { Notification } from './shared/components/notification';
import ScrollToTop from './shared/components/scrollToTop';

function App() {
  const spinner = document.getElementById('spinner');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const onClose = () => {
    setIsOpen(false);
  };

  setTimeout(() => {
    if (spinner) {
      spinner.style.display = 'none';
      setIsLoading(false);
    }
  }, 1500);

  return (
    <ChakraProvider>
      {!isLoading && (
        <div className='App'>
          <BrowserRouter>
            <Notification isOpen={isOpen} onClose={onClose} />
            <AnimatedRoutes />
            <Footer />
          </BrowserRouter>
        </div>
      )}
    </ChakraProvider>
  );
}

export default App;

const AnimatedRoutes = () => {
  let location = useLocation();

  const animVariants: Variants = {
    hidden: {
      opacity: 0,
      transition: { ease: 'easeInOut', duration: 0.6 },
    },
    visible: {
      opacity: 1,
      transition: { ease: 'easeInOut', duration: 0.6 },
    },
  };

  return (
    <AnimatePresence mode='wait'>
      <ScrollToTop />
      <Routes location={location} key={location.pathname}>
        {AppRoutes.map((route) => (
          <Route
            key={`route of ${location.pathname}`}
            path={route.path}
            element={
              <motion.div
                initial='hidden'
                animate='visible'
                exit='hidden'
                variants={animVariants}
              >
                <route.component />
              </motion.div>
            }
          />
        ))}
      </Routes>
    </AnimatePresence>
  );
};
