import { useQuery } from '@apollo/client';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useMediaQuery,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DownloadCollectionDocument } from '../../__generated__/graphql';
import { FormsTab } from './tabs/fromsTab';
import { PowersOfAttorneyTab } from './tabs/powersOfAttorneyTab';

export const Downloads: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery(DownloadCollectionDocument);
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
  const [isLargerThan2000] = useMediaQuery('(min-width: 2000px)');

  function getCurrentVw() {
    if (isLargerThan2000) return '40vw';
    if (isLargerThan1280) return '60vw';
    return '98vw';
  }

  useEffect(() => {}, [data]);

  if (loading) {
    return (
      <Stack>
        <Skeleton height="60px" />
        <Skeleton height="60px" />
        <Skeleton height="60px" />
      </Stack>
    );
  }

  return (
    <Container maxW={getCurrentVw()} mt={6}>
      <Image objectFit="cover" src="/images/logo.svg" alt="Schildereeg logo" />
      <Flex mt={16}>
        <Button
          leftIcon={<ArrowBackIcon />}
          colorScheme="blue"
          variant="solid"
          onClick={() => navigate('/')}
        >
          Zurück
        </Button>
      </Flex>

      <VStack mt={8} align={'start'} gap={2}>
        <Heading textAlign="left">
          Formular und Vollmacht / SEPA Downloads
        </Heading>
        <Text textAlign="left">
          Hier können Sie verschiedene Formulare und Vollmacht Dokumente
          herunterladen, die Sie zum Anmelden von Fahrzeugen oder dem Ändern von
          Daten benötigen.
        </Text>
      </VStack>

      <Tabs mt={8} variant="soft-rounded" colorScheme="blue">
        <TabList>
          <Tab>Formulare</Tab>
          <Tab>Vollmachten / SEPA</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <FormsTab />
          </TabPanel>
          <TabPanel>
            <PowersOfAttorneyTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};
