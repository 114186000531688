import { ArrowBackIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Button,
  Container,
  Flex,
  Heading,
  Image,
  useMediaQuery,
  VStack,
  Text,
  ListItem,
  UnorderedList,
  Link,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Dsgvo: React.FC = () => {
  const navigate = useNavigate();
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');

  return (
    <Flex>
      <Container
        maxW={isLargerThan1280 ? '60vw' : '98vw'}
        alignItems='flex-start'
        mt={16}
      >
        <Image
          objectFit='cover'
          src='/images/logo.svg'
          alt='Schildereeg logo'
        />
        <Flex mt={16}>
          <Button
            leftIcon={<ArrowBackIcon />}
            colorScheme='blue'
            variant='solid'
            onClick={() => navigate('/')}
          >
            Zurück
          </Button>
        </Flex>
        <Heading textAlign='left' mt={16}>
          Datenschutzerklärung
        </Heading>
        <Heading textAlign='left' size='md' mt={8}>
          1. Datenschutz auf einen Blick
        </Heading>
        <VStack mt={4} alignItems='left'>
          <Text as='b' textAlign='left'>
            Allgemeine Hinweise
          </Text>
          <Text textAlign='left'>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Datenpassiert, wenn Sie diese Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen
            Siepersönlich identifiziert werden können. Ausführliche
            Informationen zum Thema Datenschutz entnehmen Sie unserer unter
            diesem Text aufgeführten Datenschutzerklärung.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={8}>
          Datenerfassung auf dieser Website
        </Heading>
        <Heading textAlign='left' size='sm' mt={2}>
          Wer ist verantwortlich für die Datenerfassung auf dieser Website?
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdatenkönnen Sie dem Abschnitt
            „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
            entnehmen.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Wie erfassen wir Ihre Daten?
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben. <br /> Andere Daten werden automatisch
            oder nach Ihrer Einwilligung beim Besuch der Website durch unsere
            ITSysteme erfasst. Das sind vor allem technische Daten (z. B.
            Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
            Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
            betreten.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Wofür nutzen wir Ihre Daten?
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Welche Rechte haben Sie bezüglich Ihrer Daten?
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, können Sie diese Einwilligung
            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
            unterbestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. <br />
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
            sich jederzeit an uns wenden.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={8}>
          Analyse-Tools und Tools von Drittanbietern
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit sogenannten
            Analyseprogrammen. <br /> Detaillierte Informationen zu diesen
            Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
          </Text>
        </VStack>
        <Heading textAlign='left' size='md' mt={8}>
          2. Allgemeine Hinweise und Pflichtinformationen
        </Heading>
        <Heading textAlign='left' size='sm' mt={8}>
          Datenschutz
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. <br />
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht. <br /> Wir weisen darauf hin, dass die
            Datenübertragung im Internet (z. B. bei der Kommunikation per
            E-Mail)Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
            Daten vor dem Zugriff durch Dritte ist nicht möglich.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Hinweis zur verantwortlichen Stelle
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
            <br /> Schilder Reeg <br /> Karlheinz Reeg <br />
            (Datenschutzbeauftragter: Patric Estelmann) An der Kreuzmühle 3
            <br />
            76829 Landau in der Pfalz <br />
            Telefon: 0634133291 <br />
            E-Mail: zld.reeg@t-online.de <br />
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z. B. Namen,
            E-Mail-Adressen o. Ä.)entscheidet.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Speicherdauer
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
            ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
            zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
            wir keine anderen rechtlich zulässigen Gründe für die Speicherung
            Ihrer personenbezogenen Daten haben (z. B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
            erfolgt die Löschung nach Fortfall dieser Gründe.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf
          dieser Website
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
            wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1
            lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
            Datenkategoriennach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle
            einer ausdrücklichen Einwilligung in die Übertragung
            personenbezogener Daten in Drittstaaten erfolgt die
            Datenverarbeitung außerdem auf Grundlage von Art.49 Abs. 1 lit. a
            DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff
            auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting)
            eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf
            Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
            widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
            Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten
            wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
            Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung
            einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von
            Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf
            Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
            DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
            Rechtsgrundlagen wird in den folgenden Absätzen dieser
            Datenschutzerklärung informiert.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den
            USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten.
            Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in
            diese Drittstaaten übertragen und dort verarbeitet werden. Wir
            weisen daraufhin, dass in diesen Ländern kein mit der EU
            vergleichbares Datenschutzniveau garantiert werden kann.
            Beispielsweise sind US-Unternehmen dazu verpflichtet,
            personenbezogene Daten an Sicherheitsbehördenherauszugeben, ohne
            dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es
            kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B.
            Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
            Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
            Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen Direktwerbung (Art. 21 DSGVO)
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
            ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
            DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
            VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
            DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.
            DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
            EINLEGEN,WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
            MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
            GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
            UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
            (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). WERDEN IHRE
            PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
            BETREIBEN,SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
            VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
            DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
            SOWEIT ES MIT SOLCHER DIREKTWERBUNG INVERBINDUNG STEHT. WENN SIE
            WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
            NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCHNACH
            ART. 21 ABS. 2 DSGVO).
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Beschwerderecht bei der zuständigen Aufsichtsbehörde
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Recht auf Datenübertragbarkeit{' '}
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Auskunft, Löschung und Berichtigung
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
            uns wenden.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Recht auf Einschränkung der Verarbeitung
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen:
          </Text>
          <UnorderedList textAlign='left'>
            <ListItem>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </ListItem>
            <ListItem mt={2}>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </ListItem>
            <ListItem mt={2}>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </ListItem>
            <ListItem mt={2}>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessenüberwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </ListItem>
          </UnorderedList>
          <Text textAlign='left'>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          SSL- bzw. TLS-Verschlüsselung
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLS Verschlüsselung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von„http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            <br />
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Widerspruch gegen Werbe-E-Mails
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </Text>
        </VStack>
        <Heading textAlign='left' size='md' mt={8}>
          3. Datenerfassung auf dieser Website
        </Heading>
        <Heading textAlign='left' size='sm' mt={8}>
          Cookies
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
            kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
            Sie werden entweder vorübergehend für die Dauer einer Sitzung
            (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
            Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
            automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
            gespeichert, bis Sie diese selbst löschen oder eine automatische
            Löschung durch Ihren Webbrowser erfolgt.
            <br /> Teilweise können auch Cookies von Drittunternehmen auf Ihrem
            Endgerät gespeichert werden, wenn Sie unsere Seite betreten
            (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
            bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
            Abwicklung von Zahlungsdienstleistungen).
            <br /> Cookies haben verschiedene Funktionen. Zahlreiche Cookies
            sind technisch notwendig, da bestimmte Websitefunktionen ohne diese
            nicht funktionieren würden (z. B. die Warenkorbfunktion oder die
            Anzeige von Videos). Andere Cookies dienen dazu, das Nutzerverhalten
            auszuwerten oder Werbung anzuzeigen.
            <br /> Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs, zur Bereitstellungbestimmter, von Ihnen
            erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur
            Optimierung der Website (z. B. Cookies zur Messung des Webpublikums)
            erforderlich sind (notwendige Cookies), werden auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
            Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
            berechtigtes Interesse an der Speicherung von notwendigen Cookies
            zur technisch fehlerfreien und optimierten Bereitstellung seiner
            Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und
            vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt
            die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1TTDSG); die Einwilligung
            ist jederzeit widerrufbar.
            <br /> Sie können Ihren Browser so einstellen, dass Sie über das
            Setzen von Cookies informiert werden und Cookies nur im Einzelfall
            erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein. <br />
            Soweit Cookies von Drittunternehmen oder zu Analysezwecken
            eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
            Datenschutzerklärung gesondert informieren und ggf. eine
            Einwilligung abfragen.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Kontaktformular
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter. <br />
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher
            Maßnahmenerforderlich ist. In allen übrigen Fällen beruht die
            Verarbeitung auf unserem berechtigten Interesse an der effektiven
            Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
            DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
            sofern diese abgefragt wurde; die Einwilligung ist jederzeit
            widerrufbar. <br /> Die von Ihnen im Kontaktformular eingegebenen
            Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
            Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –insbesondere
            Aufbewahrungsfristen – bleiben unberührt.
          </Text>
        </VStack>
        <Heading textAlign='left' size='sm' mt={6}>
          Anfrage per E-Mail, Telefon oder Telefax
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
            <br /> Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art.
            6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
            Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
            Maßnahmenerforderlich ist. In allen übrigen Fällen beruht die
            Verarbeitung auf unserem berechtigten Interesse an der effektiven
            Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
            DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
            sofern diese abgefragt wurde; die Einwilligung ist jederzeit
            widerrufbar.
            <br /> Die von Ihnen an uns per Kontaktanfragen übersandten Daten
            verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt(z. B. nach abgeschlossener Bearbeitung
            Ihres Anliegens). Zwingende gesetzliche Bestimmungen –insbesondere
            gesetzliche Aufbewahrungsfristen – bleiben unberührt.
          </Text>
        </VStack>
        <Heading textAlign='left' size='md' mt={8}>
          4. Plugins und Tools
        </Heading>
        <Heading textAlign='left' size='sm' mt={8}>
          Google Maps
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
            Google Ireland Limited („Google“), Gordon House, Barrow Street,
            Dublin 4, Irland.
            <br /> Zur Nutzung der Funktionen von Google Maps ist es notwendig,
            Ihre IP-Adresse zu speichern. Diese Informationen werden in der
            Regel an einen Server von Google in den USA übertragen und dort
            gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese
            Datenübertragung. Wenn Google Maps aktiviert ist, kann Google zum
            Zwecke der einheitlichen Darstellung der Schriftarten Google Web
            Fonts verwenden. Beim Aufruf von Google Maps lädt Ihr Browser die
            benötigten Web Fonts in ihren Browsercache, um Texte und
            Schriftarten korrekt anzuzeigen.
            <br /> Die Nutzung von Google Maps erfolgt im Interesse einer
            ansprechenden Darstellung unserer Online Angebote und an einer
            leichten Auffindbarkeit der von uns auf der Website angegebenen
            Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
            Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
            abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
            Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
            soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
            auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
            ist jederzeit widerrufbar.
            <br /> Die Datenübertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gestützt. Details finden
            Sie hier:
            <br />
            <Link
              href='https://privacy.google.com/businesses/gdprcontrollerterms/'
              isExternal
            >
              Google gdprcontrollerterms <ExternalLinkIcon mx='2px' />
            </Link>
            <br />
            <Link
              href='https://business.safety.google/controllerterms/sccs/'
              isExternal
            >
              Google controllerterms sccs
              <ExternalLinkIcon mx='2px' />
            </Link>
            <br />
            Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
            Datenschutzerklärung von Google:
            <br />
            <Link href='https://policies.google.com/privacy?hl=de' isExternal>
              Google Datenschutzerklärung
              <ExternalLinkIcon mx='2px' />
            </Link>
          </Text>
        </VStack>
        <Heading textAlign='left' size='md' mt={8}>
          5. eCommerce und Zahlungsanbieter
        </Heading>
        <Heading textAlign='left' size='sm' mt={8}>
          Verarbeiten von Kunden- und Vertragsdaten
        </Heading>
        <VStack mt={2} alignItems='left'>
          <Text textAlign='left'>
            Wir erheben, verarbeiten und nutzen personenbezogene Kunden- und
            Vertragsdaten zur Begründung, inhaltlichen Ausgestaltung und
            Änderung unserer Vertragsbeziehungen. Personenbezogene Daten über
            die Inanspruchnahme dieser Website (Nutzungsdaten) erheben,
            verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem
            Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder
            abzurechnen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. b DSGVO.
            <br />
            Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
            Beendigung der Geschäftsbeziehung und Ablauf der ggf. bestehenden
            gesetzlichen Aufbewahrungsfristen gelöscht. Gesetzliche
            Aufbewahrungsfristen bleiben unberührt.
          </Text>
        </VStack>
      </Container>
    </Flex>
  );
};
