import { useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardBody,
  Center,
  Flex,
  Heading,
  Image,
  Skeleton,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { JobOfferCollectionDocument } from '../../../__generated__/graphql';
import { RHeader } from '../../../shared/components/header';

export const Jobs: React.FC = () => {
  const { data, loading } = useQuery(JobOfferCollectionDocument);
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
  const [isLargerThan2000] = useMediaQuery('(min-width: 2000px)');

  function getCurrentVw() {
    if (isLargerThan2000) return '10vw';
    if (isLargerThan1280) return '15vw';
    return '50vw';
  }

  useEffect(() => {}, [data]);

  if (loading) {
    return (
      <VStack>
        <Skeleton height='60px' />
        <Skeleton height='60px' />
        <Skeleton height='60px' />
      </VStack>
    );
  }

  return (
    <>
      {data?.jobOfferCollection?.items &&
        data.jobOfferCollection.items.length > 0 && (
          <>
            <RHeader
              mt={20}
              header='Jobangebote'
              description='WERDEN SIE TEIL DES TEAMS'
            />
            <Box mt={8}>
              {data.jobOfferCollection.items.map((item) => (
                <Card variant='filled' w='100%' key={item?.sys.id}>
                  <CardBody>
                    <Flex
                      w='100%'
                      justifyContent='flex-start'
                      flexDirection='column'
                      alignItems='flex-start'
                    >
                      <Heading size='md'>{item?.header}</Heading>
                      <Text mt='2'>{item?.description}</Text>
                    </Flex>
                  </CardBody>
                </Card>
              ))}
            </Box>
          </>
        )}
    </>
  );
};
