import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { RHeader } from '../../../shared/components/header';

export const GoogleMaps: React.FC = () => {
  const [useMaps, setUseMaps] = React.useState<boolean>(false);
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  return (
    <Flex mt={20}>
      <Stack direction={isLargerThan800 ? 'row' : 'column'} gap={4} w='100%'>
        <VStack alignItems='flex-start' w='100%'>
          <RHeader
            header='Wir freuen uns auf Sie'
            description='FÜR SIE VOR ORT'
          />
          <Text align='left'>
            Zulassungsdienst Reeg An der Kreuzmühle 3 76829 Landau
          </Text>
          <Text as='b' align='left'>
            Fax: 06341 / 932588
          </Text>
          <Text as='b' align='left'>
            Tel.: 06341 / 33291
          </Text>
        </VStack>
        <AspectRatio w='100%' ratio={16 / 9}>
          {useMaps ? (
            <iframe src='https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=de&amp;q=An%20der%20Kreuzm%C3%BChle%203%2076829%20Landau+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed' />
          ) : (
            <Box bg='gray.200' borderRadius={8}>
              <Stack>
                <Text>
                  Sind Sie mit der Verwendung, <br /> von google maps
                  einverstanden?
                </Text>
                <Button onClick={() => setUseMaps(true)} colorScheme='blue'>
                  Öffnen
                </Button>
              </Stack>
            </Box>
          )}
        </AspectRatio>
      </Stack>
    </Flex>
  );
};
