import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  CardBody,
  Heading,
  Highlight,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ServiceOfferCollectionDocument } from '../../../__generated__/graphql';

export const ServicePriceCards: React.FC<{}> = () => {
  const { data, loading } = useQuery(ServiceOfferCollectionDocument);

  useEffect(() => {}, [data]);

  if (loading) {
    return (
      <Stack>
        <Skeleton height='60px' />
        <Skeleton height='60px' />
        <Skeleton height='60px' />
      </Stack>
    );
  }

  return (
    <>
      {data?.serviceOfferCollection && data?.serviceOfferCollection.items && (
        <VStack mt={8}>
          {data?.serviceOfferCollection.items.map((item) => (
            <Card variant='filled' w='100%'>
              <CardBody>
                <Stack width='100%' alignItems='flex-start' gap={2}>
                  <Heading size='md'>{item?.header}</Heading>
                  <Text w='100%' textAlign='left'>
                    <Highlight
                      query={
                        item?.highlighted ? item?.highlighted.split('//') : ''
                      }
                      styles={{
                        px: '2',
                        py: '1',
                        rounded: 'full',
                        bg: 'green.100',
                      }}
                    >
                      {item?.description ?? ''}
                    </Highlight>
                  </Text>
                  <Link to={`/service-details/${item?.details?.sys.id}`}>
                    <Button variant='solid' colorScheme='blue'>
                      Jetzt informieren
                    </Button>
                  </Link>
                </Stack>
              </CardBody>
            </Card>
          ))}
        </VStack>
      )}
    </>
  );
};
