import { useQuery } from "@apollo/client";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Skeleton,
  Stack,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { PowersOfAttorneyCollectionDocument } from "../../../__generated__/graphql";

export const PowersOfAttorneyTab = () => {
  const { data, loading } = useQuery(PowersOfAttorneyCollectionDocument);
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");

  useEffect(() => {}, [data]);

  if (loading) {
    return (
      <Stack>
        <Skeleton height="60px" />
        <Skeleton height="60px" />
        <Skeleton height="60px" />
      </Stack>
    );
  }

  return (
    <Box>
      {data?.powersOfAttorneyCollection && (
        <VStack gap={2} mt={2}>
          {data?.powersOfAttorneyCollection.items.map((item: any) => (
            <Card variant="filled" w="100%">
              <CardBody>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection={isLargerThan600 ? "row" : "column"}
                  gap={isLargerThan600 ? "0" : "4"}
                >
                  <Text>{item?.title}</Text>
                  <a
                    href={item?.file?.url || ""}
                    download="MyExampleDoc"
                    target="_blank"
                  >
                    <Button rightIcon={<DownloadIcon />} colorScheme="blue">
                      Download
                    </Button>
                  </a>
                </Flex>
              </CardBody>
            </Card>
          ))}
        </VStack>
      )}
    </Box>
  );
};
