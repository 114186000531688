import React, { useEffect } from 'react';

import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  Heading,
  Image,
  Skeleton,
  Stack,
  useMediaQuery,
  Text,
  Center,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FaqQuestionCollectionDocument } from '../../__generated__/graphql';

export const Faq: React.FC = () => {
  const navigate = useNavigate();
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
  const [isLargerThan2000] = useMediaQuery('(min-width: 2000px)');
  const { data, loading } = useQuery(FaqQuestionCollectionDocument);

  function getCurrentVw() {
    if (isLargerThan2000) return '10vw';
    if (isLargerThan1280) return '15vw';
    return '50vw';
  }
  useEffect(() => {}, [data]);

  return (
    <Flex>
      <Container
        maxW={isLargerThan1280 ? '60vw' : '98vw'}
        alignItems='flex-start'
        mt={16}
      >
        <Image
          objectFit='cover'
          src='/images/logo.svg'
          alt='Schildereeg logo'
        />
        <Flex mt={16}>
          <Button
            leftIcon={<ArrowBackIcon />}
            colorScheme='blue'
            variant='solid'
            onClick={() => navigate('/')}
          >
            Zurück
          </Button>
        </Flex>
        <Heading textAlign='left' mt={16}>
          FAQ
        </Heading>

        <Center mt={12}>
          <VStack gap={4}>
            <Image
              maxW={getCurrentVw()}
              height='auto'
              objectFit='cover'
              src='/images/working.svg'
              alt='404 page illustration'
            />
            <Heading size='md'>
              Aktuell arbeiten wir noch an unsere FAQ Saite, in Kürze finden Sie
              hoffentlich antworten auf alle ihre Fragen. Bis dahin rufen Sie
              uns doch am besten einfach an unter der 06341 / 33291.
            </Heading>
          </VStack>
        </Center>

        {/* FIXME: {data?.faqQuestionCollection && data.faqQuestionCollection.items && (
          <>
            <Box mt={6}>
              {data.faqQuestionCollection.items.map((entry) => (
                <Card variant='filled' w='100%'>
                  <CardBody>
                    <Flex
                      w='100%'
                      alignItems='flex-start'
                      flexDirection='column'
                    >
                      <Heading size='md'>{entry?.header}</Heading>
                      {JSON.stringify(entry?.awnser?.json)}
                    </Flex>
                  </CardBody>
                </Card>
              ))}
            </Box>
          </>
        )} */}
      </Container>
    </Flex>
  );
};
