import { useQuery } from '@apollo/client';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { NotificationCollectionDocument } from '../../__generated__/graphql';

interface props {
  isOpen: boolean;
  onClose: () => void;
}

export const Notification: React.FC<props> = (props: props) => {
  const { isOpen, onClose } = props;
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const { data, loading } = useQuery(NotificationCollectionDocument);

  useEffect(() => {
    if (
      data?.notificationCollection?.items &&
      data?.notificationCollection?.items[0]
    ) {
      validateNotificationDate(
        data?.notificationCollection.items[0].activeUntil
      );
    }
  }, [data]);

  if (loading) {
    return <></>;
  }

  function validateNotificationDate(date: string) {
    if (!date) return setIsValid(false);

    const targetDate = new Date(date);
    const currentDate = new Date();

    return targetDate < currentDate ? setIsValid(false) : setIsValid(true);
  }

  return (
    <>
      {isOpen &&
        isValid &&
        data?.notificationCollection?.items &&
        data?.notificationCollection?.items[0] && (
          <Alert
            status={
              (data.notificationCollection.items[0].type as any) ?? 'info'
            }
          >
            <AlertIcon />
            <Box w='100%'>
              <AlertTitle>
                {data.notificationCollection.items[0].header}
              </AlertTitle>
              <AlertDescription>
                {data.notificationCollection.items[0].description}
              </AlertDescription>
            </Box>

            <CloseButton
              alignSelf='flex-start'
              position='relative'
              right={-1}
              top={-1}
              onClick={onClose}
            />
          </Alert>
        )}
    </>
  );
};
