import { useQuery } from '@apollo/client';
import { DownloadIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  Flex,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import { DownloadEntryDocument } from '../../../__generated__/graphql';

interface props {
  id: string;
}

export const ServiceDetailsDownload: React.FC<props> = (props: props) => {
  const { id } = props;
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');
  const { data, loading } = useQuery(DownloadEntryDocument, {
    variables: { id },
  });

  return (
    <>
      <Stack gap={2} mt={6}>
        <Card variant='filled' w='100%'>
          <CardBody>
            <Flex
              justifyContent='space-between'
              alignItems='center'
              flexDirection={isLargerThan600 ? 'row' : 'column'}
            >
              <Text>{data?.download?.header}</Text>
              <a
                href={data?.download?.file?.url || ''}
                download='MyExampleDoc'
                target='_blank'
              >
                <Button rightIcon={<DownloadIcon />} colorScheme='blue'>
                  Download
                </Button>
              </a>
            </Flex>
          </CardBody>
        </Card>
      </Stack>
    </>
  );
};
