import { useQuery } from '@apollo/client';
import {
  Card,
  CardBody,
  Flex,
  Heading,
  Highlight,
  VStack,
  Skeleton,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { ProductOfferCollectionDocument } from '../../../__generated__/graphql';

export const ProductPriceCards: React.FC = () => {
  const { data, loading } = useQuery(ProductOfferCollectionDocument);

  useEffect(() => {}, [data]);

  if (loading) {
    return (
      <VStack>
        <Skeleton height='60px' />
        <Skeleton height='60px' />
        <Skeleton height='60px' />
      </VStack>
    );
  }

  return (
    <>
      {data?.productOfferCollection?.items && (
        <VStack mt={8}>
          {data?.productOfferCollection.items.map((item) => (
            <Card variant='filled' w='100%' key={item?.sys.id}>
              <CardBody>
                <Flex w='100%' justifyContent='space-between'>
                  <Heading size='md'>{item?.header}</Heading>
                  <Heading size='md'>
                    <Highlight
                      query={`Stk. ${item?.price}€`}
                      styles={{
                        px: '2',
                        py: '1',
                        rounded: 'full',
                        bg: 'green.100',
                      }}
                    >
                      {`Stk. ${item?.price}€`}
                    </Highlight>
                  </Heading>
                </Flex>
              </CardBody>
            </Card>
          ))}
        </VStack>
      )}
    </>
  );
};
