import { useQuery } from '@apollo/client';
import { TimeIcon } from '@chakra-ui/icons';
import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Skeleton,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { OpeningHoursCollectionDocument } from '../../__generated__/graphql';

export const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const { data, loading } = useQuery(OpeningHoursCollectionDocument);
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  return (
    <Box>
      <Box width="100%" p={10} bg="gray.800" mt={20}>
        <Container maxW={isLargerThan900 ? '60vw' : '98vw'}>
          <Flex
            flexDirection={isLargerThan900 ? 'row' : 'column'}
            justifyContent={isLargerThan900 ? 'space-between' : 'center'}
            alignItems={isLargerThan900 ? 'space-between' : 'center'}
            gap={8}
          >
            <Box width="100%">
              <Heading textAlign="left" size="md" color="white">
                Öffnungszeiten
              </Heading>
              <VStack mt={4} alignItems="left">
                {loading && (
                  <>
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                  </>
                )}
                {!loading &&
                  data?.openingHoursCollection &&
                  data.openingHoursCollection.items.map((item) => (
                    <Text textAlign="left" color="white">
                      {item?.weekday} <br />
                      {item?.openingHours}
                    </Text>
                  ))}
              </VStack>
            </Box>
            <Box width="100%">
              <VStack alignItems={isLargerThan900 ? 'center' : 'left'}>
                <Link to="/impressum">
                  <Text align="left" color="white">
                    Impressum
                  </Text>
                </Link>
                <Link to="/dsgvo">
                  <Text align="left" color="white">
                    DsGVo
                  </Text>
                </Link>
                <Link to="/faq">
                  <Text align="left" color="white">
                    FAQ
                  </Text>
                </Link>
                <Text align="left" color="white">
                  <a href="mailto:zld.reeg@t-online.de">Kontakt</a>
                </Text>
              </VStack>
            </Box>
            <Box width="100%">
              <Flex
                justifyContent={isLargerThan900 ? 'flex-end' : 'flex-start'}
              >
                <Image
                  objectFit="cover"
                  src="/images/logo_inverted.svg"
                  alt="Schildereeg logo"
                />
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box
        p={8}
        w={'full'}
        alignItems={'center'}
        justifyItems={'center'}
        bg="gray.900"
      >
        <Text color="white">
          © {currentYear} Zulassungsdienst und Schilderservice Reeg{' '}
        </Text>
      </Box>
    </Box>
  );
};
