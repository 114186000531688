import {
  AtSignIcon,
  AttachmentIcon,
  QuestionOutlineIcon,
} from '@chakra-ui/icons';
import { Flex, Grid, GridItem, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { HelpCard } from './helpCard';

export const HelpCardGrid: React.FC = () => {
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  return (
    <Grid
      templateColumns={isLargerThan600 ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)'}
      gap={6}
      mt={8}
    >
      <GridItem w='100%'>
        <HelpCard
          heading={'FAQ'}
          description={'Hier werden die häufigste Fragen beantwortet.'}
          icon={<QuestionOutlineIcon />}
          buttonText={'FAQ'}
          link='faq'
        />
      </GridItem>
      <GridItem w='100%'>
        <HelpCard
          heading={'Downloads'}
          description={'Hier finden Sie alle benötigten Formulare.'}
          icon={<AttachmentIcon />}
          buttonText={'Downloads'}
          link='downloads'
        />
      </GridItem>
      <GridItem w='100%'>
        <HelpCard
          heading={'Kontakt'}
          description={
            'Schreiben Sie uns eine Email mit ihrem Persönlichen anliegen.'
          }
          icon={<AtSignIcon />}
          buttonText={'Kontakt'}
          link=''
          mailTo
        />
      </GridItem>
    </Grid>
  );
};
