import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface props {
  heading: string;
  description: string;
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  link: string;
  buttonText: string;
  mailTo?: boolean;
}

export const HelpCard: React.FC<props> = (props: props) => {
  const { icon, heading, description, buttonText, mailTo: href } = props;
  const navigate = useNavigate();

  return (
    <Card variant='filled' height='100%' width='100%'>
      <CardBody>
        <Stack width='100%' alignItems='flex-start'>
          <Heading size='md'>{heading}</Heading>
          <Text w='100%' textAlign='left'>
            {description}
          </Text>
        </Stack>
      </CardBody>
      <CardFooter>
        {!href ? (
          <Button
            variant='solid'
            w='100%'
            leftIcon={icon}
            colorScheme='blue'
            onClick={() => navigate(props.link)}
          >
            {buttonText}
          </Button>
        ) : (
          <Button
            variant='solid'
            w='100%'
            leftIcon={icon}
            colorScheme='blue'
            onClick={(e) => {
              window.location.href = 'mailto:zld.reeg@t-online.de';
            }}
          >
            <a>{buttonText}</a>
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};
