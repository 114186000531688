import { Imprint } from './pages/imprint/Imprint';
import { Dsgvo } from './pages/dsgvo/Dsgvo';
import { Downloads } from './pages/downloads/Downloads';
import { ServiceDetails } from './pages/serviceDetails/ServiceDetails';

import { Home } from './pages/home/Home';
import { Faq } from './pages/faq/Faq';
export const AppRoutes = [
  { name: 'Home', path: '/', component: Home },
  { name: 'Impressum', path: '/impressum', component: Imprint },
  { name: 'DSGVO', path: '/dsgvo', component: Dsgvo },
  { name: 'Downloads', path: '/downloads', component: Downloads },
  { name: 'FAQ', path: '/faq', component: Faq },
  {
    name: 'Service Details',
    path: '/service-details/:serviceId',
    component: ServiceDetails,
  },
];
