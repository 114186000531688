import {
  AspectRatio,
  Box,
  Flex,
  Image,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

export const AboutUs: React.FC = () => {
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  return (
    <Flex>
      <Stack direction={isLargerThan800 ? 'row' : 'column'} gap={4}>
        <VStack gap={4} w='100%'>
          <Text align='left'>
            Unser Familienunternehmen wurde am 01. März 1967 von Alexander Reeg
            in Landau gegründet und wird nun in nächster Generation von
            Karlheinz Reeg weitergeführt. Damals war es die erste Firma, die für
            die Stadt Landau und für den Kreis SÜW einen Schilder-Service
            entwickelte und anbot.
          </Text>
          <Text align='left'>
            Im Laufe der Jahre konnte sich die Firma mit ihren Autoschildern und
            ihrem Zulassungsservice fest etablieren. Um neusten Anforderungen zu
            entsprechen, wurde der geschäftliche Anlaufpunkt in unmittelbarer
            Nachbarschaft zu den Zulassungsstellen erneuert und das Geschäft
            vollumfänglich modernisiert. Denn: Wir möchten weiterhin Ihr
            professioneller Partner sein, wenn es um Schilder geht.
          </Text>
        </VStack>
        <Box w='100%' borderRadius={4}>
          <Image
            objectFit='cover'
            src='/images/reeg_patrick.png'
            alt='Dan Abramov'
          />
        </Box>
      </Stack>
    </Flex>
  );
};
