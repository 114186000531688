import React from 'react';
import {
  Center,
  Heading,
  Image,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';

export const EmptyStat: React.FC = () => {
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
  const [isLargerThan2000] = useMediaQuery('(min-width: 2000px)');

  function getCurrentVw() {
    if (isLargerThan2000) return '10vw';
    if (isLargerThan1280) return '15vw';
    return '50vw';
  }

  return (
    <Center mt={12}>
      <VStack gap={4}>
        <Image
          maxW={getCurrentVw()}
          height='auto'
          objectFit='cover'
          src='/images/404_icon.svg'
          alt='404 page illustration'
        />
        <Heading size='md'>Da ist leider etwas schief gelaufen.</Heading>
      </VStack>
    </Center>
  );
};
