import { ArrowBackIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Imprint: React.FC = () => {
  const navigate = useNavigate();
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
  return (
    <Flex>
      <Container
        maxW={isLargerThan1280 ? '60vw' : '98vw'}
        alignItems="flex-start"
        mt={16}
      >
        <Image
          objectFit="cover"
          src="/images/logo.svg"
          alt="Schildereeg logo"
        />
        <Flex mt={16}>
          <Button
            leftIcon={<ArrowBackIcon />}
            colorScheme="blue"
            variant="solid"
            onClick={() => navigate('/')}
          >
            Zurück
          </Button>
        </Flex>
        <Heading textAlign="left" mt={16}>
          Impressum
        </Heading>
        <Heading textAlign="left" size="md" mt={8}>
          Angaben gemäß § 5 TMG
        </Heading>
        <VStack mt={4} alignItems="left">
          <Text textAlign="left">Karlheinz Reeg</Text>
          <Text textAlign="left">Zulassungsdienst und Schilderservice</Text>
          <Text textAlign="left">An der Kreuzmühle 3</Text>
          <Text textAlign="left">76829 Landau</Text>
        </VStack>
        <Heading textAlign="left" size="md" mt={8}>
          Kontakt
        </Heading>
        <VStack mt={4} alignItems="left">
          <Text textAlign="left">Telefon: 06341 33291</Text>
          <Text textAlign="left">Fax: 06341 932588</Text>
          <Text textAlign="left">E-Mail: zld.reeg@t-online.de</Text>
        </VStack>
        <Heading textAlign="left" size="md" mt={8}>
          Umsatzsteuer-ID
        </Heading>
        <VStack mt={4} alignItems="left">
          <Text textAlign="left">
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
            148838876
          </Text>
        </VStack>
        <Heading textAlign="left" size="md" mt={8}>
          EU-Streitschlichtung
        </Heading>
        <VStack mt={4} alignItems="left">
          <Text textAlign="left">
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:
            <br />
            <Link href="https://ec.europa.eu/consumers/odr/" isExternal>
              Online-Streitbeilegung <ExternalLinkIcon mx="2px" />
            </Link>
            <br />
            Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </Text>
        </VStack>
        <Heading textAlign="left" size="md" mt={8}>
          Verbraucher-streitbeilegung / Universal-schlichtungsstelle
        </Heading>
        <VStack mt={4} alignItems="left">
          <Text textAlign="left">
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </Text>
        </VStack>
        <VStack mt={12} alignItems="left">
          <Heading textAlign="left" size="md">
            Technische Umsetzung und Webdesign
          </Heading>
          <Text textAlign="left">
            Agentur Dietrich & Paul
            <br />
            <Link href="https://www.paul-dietrich.de/" isExternal>
              www.dietrich-paul.de
              <ExternalLinkIcon mx="2px" />
            </Link>
          </Text>
        </VStack>
      </Container>
    </Flex>
  );
};
