import { AtSignIcon } from '@chakra-ui/icons';
import { FiInstagram } from 'react-icons/fi';
import {
  Box,
  Flex,
  Stack,
  useMediaQuery,
  VStack,
  Text,
  Image,
  Card,
  CardBody,
  Button,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import React from 'react';
import { RHeader } from '../../../shared/components/header';

export const Hero: React.FC = () => {
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  return (
    <Flex mt={10} mb={20}>
      <Card variant='unstyled'>
        <CardBody>
          <Flex direction={isLargerThan800 ? 'row' : 'column'}>
            <VStack
              alignItems='flex-start'
              w='100%'
              justifyContent='space-between'
            >
              <Image
                objectFit='cover'
                src='/images/logo.svg'
                alt='Schildereeg logo'
              />
              <VStack mt={20} alignItems='flex-start'>
                <RHeader
                  header='Für Sie vor Ort'
                  description='SEIT ÜBER 50 JAHREN'
                />
                <Text align='left'>
                  Wir sind Ihre kompetenten Ansprechpartner, wenn es um
                  Kraftfahrzeugschilder und das Zulassen bzw. Abmelden von
                  Fahrzeugen geht.
                </Text>
                <HStack>
                  <Button colorScheme='blue' leftIcon={<AtSignIcon />}>
                    <a href='mailto:zld.reeg@t-online.de'>Kontakt</a>
                  </Button>
                  <a
                    href='https://www.instagram.com/schilder_reeg/'
                    target='_blank'
                  >
                    <IconButton
                      variant='outline'
                      colorScheme='green'
                      aria-label='Instagram'
                      fontSize='20px'
                      icon={<FiInstagram />}
                    />
                  </a>
                </HStack>
              </VStack>
            </VStack>
            <Box w='100%' mt={isLargerThan800 ? 0 : 8}>
              <Image
                borderRadius={4}
                objectFit='cover'
                src='/images/hero.png'
                alt='Ein Bild des Ladens'
              />
            </Box>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};
