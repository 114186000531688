import { Container, Flex, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { RHeader } from '../../shared/components/header';
import { AboutUs } from './components/aboutUs';
import { GoogleMaps } from './components/googleMaps';
import { HelpCardGrid } from './components/helpCardGrid';
import { Hero } from './components/hero';
import { ProductPriceCards } from './components/productPriceCards';
import { ServicePriceCards } from './components/servicePriceCards';
import { Jobs } from './components/job';

export const Home: React.FC = () => {
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
  const [isLargerThan2000] = useMediaQuery('(min-width: 2000px)');

  function getCurrentVw() {
    if (isLargerThan2000) return '40vw';
    if (isLargerThan1280) return '60vw';
    return '98vw';
  }

  return (
    <Container maxW={getCurrentVw()}>
      <Hero />
      <Flex width='100%' direction='column'>
        <RHeader
          header='Unsere Dienstleistungen'
          description='PERSÖNLICH, SCHNELL & ZUVERLÄSSIG'
        />
        <ServicePriceCards />
        <RHeader
          mt={20}
          header='Unser Schilderservice'
          description='WIR ERSTELLEN IHR KENNZEICHEN'
        />
        <ProductPriceCards />
        <RHeader
          mt={20}
          header='Wir helfen Ihnen gerne'
          description='SIE HABEN NOCH FRAGEN?'
        />
        <HelpCardGrid />
        <RHeader mt={20} header='Über uns' description='SEIT 1967 IN LANDAU' />
        <AboutUs />
        <GoogleMaps />
        <Jobs />
      </Flex>
    </Container>
  );
};
