import { Flex, VStack, Heading, Text } from '@chakra-ui/react';
import React from 'react';

interface props {
  header?: string;
  description?: string;
  mt?: number | string;
}

export const RHeader: React.FC<props> = (props: props) => {
  const { header, description, mt } = props;
  return (
    <Flex mt={mt} width='100%' direction='column'>
      <VStack alignItems='flex-start'>
        <Text align='left'>{description}</Text>
        <Heading textAlign='left'>{header}</Heading>
      </VStack>
    </Flex>
  );
};
