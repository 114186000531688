import { useQuery } from '@apollo/client';
import { AddIcon, ArrowBackIcon, CheckCircleIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  Skeleton,
  Stack,
  Text,
  VStack,
  useMediaQuery,
  OrderedList,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceOfferDetailsEntryDocument } from '../../__generated__/graphql';
import { EmptyStat } from '../../shared/components/emptyState';
import { ServiceDetailsDownload } from './components/serviceDetailsDownloads';

export const ServiceDetails: React.FC = () => {
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
  const [isLargerThan2000] = useMediaQuery('(min-width: 2000px)');
  const { data, loading } = useQuery(ServiceOfferDetailsEntryDocument, {
    variables: { id: serviceId ?? '' },
  });

  useEffect(() => {}, [data]);

  function getCurrentVw() {
    if (isLargerThan2000) return '40vw';
    if (isLargerThan1280) return '60vw';
    return '98vw';
  }

  if (loading) {
    return (
      <VStack>
        <Skeleton height='400px' />
        <Skeleton height='60px' />
        <Skeleton height='60px' />
      </VStack>
    );
  }

  return (
    <>
      <Container maxW={getCurrentVw()} mt={6}>
        <Image
          objectFit='cover'
          src='/images/logo.svg'
          alt='Schildereeg logo'
        />
        <Flex mt={16}>
          <Button
            leftIcon={<ArrowBackIcon />}
            colorScheme='blue'
            variant='solid'
            onClick={() => navigate('/')}
          >
            Zurück
          </Button>
        </Flex>
        {!data?.serviceOfferDetails && <EmptyStat />}
        {data && (
          <>
            <Heading textAlign='left' mt={16} textColor='blue.500'>
              {data.serviceOfferDetails?.prices?.map((price) => (
                <>
                  {price}
                  <br />
                </>
              ))}
            </Heading>
            <Text mt={4} textAlign='left'>
              {data.serviceOfferDetails?.addonFees?.map((fee) => (
                <>
                  {fee}
                  <br />
                </>
              ))}
            </Text>
            <Heading textAlign='left' mt={4}>
              {data.serviceOfferDetails?.header}
            </Heading>

            <Text mt={4} textAlign='left'>
              {data.serviceOfferDetails?.description}
            </Text>

            {data.serviceOfferDetails?.includedServices &&
              data.serviceOfferDetails?.includedServices?.length > 0 && (
                <>
                  <Heading size='md' textAlign='left' mt={4}>
                    Inkludierte Leistungen
                  </Heading>

                  <OrderedList mt={4} spacing={2} textAlign='left'>
                    {data.serviceOfferDetails?.includedServices?.map(
                      (entry) => (
                        <ListItem>{entry}</ListItem>
                      )
                    )}
                  </OrderedList>
                </>
              )}

            {data.serviceOfferDetails?.downloadsCollection && (
              <>
                {data.serviceOfferDetails?.downloadsCollection.items.length >
                  0 && (
                  <Heading size='md' textAlign='left' mt={4}>
                    Sie benötigen folgende Dokumente
                  </Heading>
                )}
                {data.serviceOfferDetails.downloadsCollection.items.map(
                  (entry) => (
                    <>
                      {entry?.sys.id && (
                        <ServiceDetailsDownload id={entry.sys.id} />
                      )}
                    </>
                  )
                )}
              </>
            )}
            <Stack gap={4}>
              {data.serviceOfferDetails?.checklist && (
                <Box>
                  <Heading size='md' textAlign='left' mt={4}>
                    Desweiteren benötigen wir von Ihnen
                  </Heading>
                  <List mt={4} spacing={2} textAlign='left'>
                    {data.serviceOfferDetails.checklist.map((entry) => (
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color='green.500' />
                        {entry}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
              {data.serviceOfferDetails?.commercialChecklist && (
                <Box>
                  <Heading size='md' textAlign='left' mt={4}>
                    Bei gewerblichen Zulassungen benötigen wir zusätzlich
                  </Heading>
                  <List mt={4} spacing={2} textAlign='left'>
                    {data.serviceOfferDetails.commercialChecklist.map(
                      (entry) => (
                        <ListItem>
                          <ListIcon as={CheckCircleIcon} color='green.500' />
                          {entry}
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>
              )}
              {data.serviceOfferDetails?.newCarChecklist && (
                <Box>
                  <Heading size='md' textAlign='left' mt={4}>
                    Bei Neufahrzeugen
                  </Heading>
                  <List mt={4} spacing={2} textAlign='left'>
                    {data.serviceOfferDetails.newCarChecklist.map((entry) => (
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color='green.500' />
                        {entry}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
              {data.serviceOfferDetails?.checklistImportVehicles && (
                <Box>
                  <Heading size='md' textAlign='left' mt={4}>
                    Bei Importfahrzeugen
                  </Heading>
                  <List mt={4} spacing={2} textAlign='left'>
                    {data.serviceOfferDetails.checklistImportVehicles.map(
                      (entry) => (
                        <ListItem>
                          <ListIcon as={CheckCircleIcon} color='green.500' />
                          {entry}
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>
              )}
              {data.serviceOfferDetails?.checklistAdmissionSheetLost && (
                <Box>
                  <Heading size='md' textAlign='left' mt={4}>
                    Bei Kfz Scheinverlust
                  </Heading>
                  <List mt={4} spacing={2} textAlign='left'>
                    {data.serviceOfferDetails.checklistAdmissionSheetLost.map(
                      (entry) => (
                        <ListItem>
                          <ListIcon as={CheckCircleIcon} color='green.500' />
                          {entry}
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>
              )}
              {data.serviceOfferDetails?.checklistLostLicensePlate && (
                <Box>
                  <Heading size='md' textAlign='left' mt={4}>
                    Bei Kennzeichenverlust
                  </Heading>
                  <List mt={4} spacing={2} textAlign='left'>
                    {data.serviceOfferDetails.checklistLostLicensePlate.map(
                      (entry) => (
                        <ListItem>
                          <ListIcon as={CheckCircleIcon} color='green.500' />
                          {entry}
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>
              )}
            </Stack>
          </>
        )}
      </Container>
    </>
  );
};
