import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// FIXME: Create new api credentials and delete the old one => also move credential into a env file
const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/x5sf0ntqjneh/environments/master?access_token=YtXS9TqheVAUDaVwoma8hLWMbg5i3VoIxy5M_YMv0Tw`,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
